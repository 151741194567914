<template>
  <div class="document">
    <div class="document__thumb">
      <div
        @click="download"
        class="document__thumb-button"
        role="button">
        <download-icon class="document__thumb-button-icon"/>
        <a
          ref="downloadAnchor"
          :href="fullUrl"
          :download="filename"
          target="_blank"
          style="display: none;"></a>
      </div>
      <file-document-icon class="document__thumb-icon"/>
    </div>
    <div class="document__info">
      <div class="document__text" :title="filename">{{ filename | truncate(24) }}</div>
      <div class="document__footer">
        <div class="document__miniature">
          <div class="document__miniature-text">{{ extension }}</div>
          <file-document-icon :class="iconClass"/>
        </div>
        <div class="document__text document__text--copy">{{ size | filesize }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/vue-apollo';

export default {
  props: {
    message: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      token: getToken(),
    };
  },
  computed: {
    tokenQuery() {
      return `?token=${this.token}`;
    },
    multimedia() {
      return this.message.multimedia ?? {};
    },
    fullUrl() {
      return this.multimedia.file ? `${this.multimedia.file}${this.tokenQuery}` : '';
    },
    filename() {
      return this.multimedia.filename ?? 'file';
    },
    size() {
      return Number.parseInt(this.multimedia.size ?? 0, 10);
    },
    extension() {
      return this.multimedia.ext ?? '';
    },
    isPdf() {
      return this.extension.toLowerCase() === 'pdf';
    },
    iconClass() {
      return [
        'document__miniature-icon',
        {
          'document__miniature-icon--pdf': this.isPdf,
        },
      ];
    },
  },
  methods: {
    download() {
      // TODO implement force download
      this.$refs.downloadAnchor.click();
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_index.scss';
</style>
